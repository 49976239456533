import { gql } from 'data/graphql/utils/gql';

export const fImage = gql`
  fragment fImage on Asset {
    url
    title
    description
    width
    height
  }
`;

export const fCity = gql`
  fragment fCity on City {
    name
    country {
      name
      acronym
    }
  }
`;

export const fLocation = gql`
  fragment fLocation on Location {
    lat
    lon
  }
`;

export const fSpeciality = gql`
  fragment fSpeciality on SpecialityPage {
    title
    slug
    specialityName
    disableLocale
  }
`;

export const fDoctorProfileWithTreatments = gql`
  fragment fDoctorProfileWithTreatments on DoctorProfile {
    firstName
    lastName
    title
    postTitle
    qunoscore
    qunoscorePoints
    slug
    doctorImage {
      ...fImage
    }
    treatmentsCollection {
      items {
        name
        salesforceTreatment
      }
    }
    yearsOfExperienceAsDoctor
    treatmentsPerformedLastYear
  }

  ${fImage}
`;

export const fPackageHighlights = gql`
  fragment fPackageHighlights on Packages {
    id
    name
    type
    description
    fullDays
    deal
    price
    discountedPriceEuros
    priceDollars
    discountedPriceDollars
    pricePounds
    discountedPricePounds
    dealStartDate
    dealEndDate
    startingFromPrice
  }
`;

export const fPackageHighlightsClinicsPage = gql`
  fragment fPackageHighlightsClinicsPage on Packages {
    deal
    price
  }
`;

export const fAccommodation = gql`
  fragment fAccommodation on Accommodation {
    name
    about {
      json
    }
    stars
    address
    location {
      ...fLocation
    }
    photos: photosCollection(limit: 10) {
      items {
        ...fImage
      }
    }
  }

  ${fImage}
`;

export const fTreatment = gql`
  fragment fTreatment on Treatment {
    sys {
      id
    }
    name
    salesforceTreatment
    disableLocale
  }
`;

export const fTreatmentWithPackageCategories = gql`
  fragment fTreatmentWithPackageCategories on Treatment {
    ...fTreatment
  }

  ${fTreatment}
`;

export const fStaffTreatment = gql`
  fragment fStaffTreatment on StaffTreatment {
    yearsOfExperience
    treatmentsPerformed
    topTreatment
    treatment {
      ...fTreatmentWithPackageCategories
    }
    languagesSpoken: languagesSpokenCollection(limit: 10) {
      items {
        language
      }
    }
    packages: packagesCollection(limit: 10) {
      items {
        treatment {
          ...fTreatmentWithPackageCategories
        }
        ...fPackageHighlights
        doNotShowOnProfile
        salesforceRecordType
        qunoPerksText
        perksText
        treatmentPlanText
      }
    }
  }

  ${fTreatmentWithPackageCategories}
  ${fPackageHighlights}
  ${fAccommodation}
`;

export const cbAccordionBlock = gql`
  fragment cbAccordionBlock on AccordionBlock {
    __typename
    sys {
      id
    }
    blockId
    disableLocale
    isFaq
    accordionItems: accordionItemsCollection(limit: 10) {
      items {
        __typename
        sys {
          id
        }
        title
        disableLocale
        text {
          json
        }
      }
    }
  }
`;

export const cbBlockAvatargrid = gql`
  fragment cbBlockAvatargrid on BlockAvatargrid {
    __typename
    sys {
      id
    }
    blockId
    disableLocale
    title
    descriptionContent {
      json
    }
    avatars: avatarsCollection(limit: 30) {
      items {
        __typename
        sys {
          id
        }
        title
        image {
          ...fImage
        }
        description
        descriptionLink
      }
    }
  }

  ${fImage}
`;

export const cbBlockBanner = gql`
  fragment cbBlockBanner on BlockBanner {
    __typename
    sys {
      id
    }
    blockId
    disableLocale
    title
    textContent: text {
      json
    }
    backgroundImage {
      ...fImage
    }
    fullWidth
    theme
    preTitle
    highlight1
    highlight2
    highlight3
    funnelPosition
    funnel {
      ...cbBlockFunnel
    }
  }

  ${fImage}
`;

export const cbBlockButton = gql`
  fragment cbBlockButton on BlockButton {
    __typename
    sys {
      id
    }
    blockId
    disableLocale
    text
    link
    target
    position
    theme
    triggerLeadForm
  }
`;

export const cbBlockCarousel = gql`
  fragment cbBlockCarousel on BlockCarousel {
    __typename
    blockId
    disableLocale
    title
    slides: slidesCollection(limit: 10) {
      items {
        description
        image {
          ...fImage
        }
      }
    }
  }

  ${fImage}
`;

export const cbBlockChecklist = gql`
  fragment cbBlockChecklist on BlockChecklist {
    __typename
    blockId
    headline
    items
  }
`;

export const cbBlockEmbed = gql`
  fragment cbBlockEmbed on BlockEmbed {
    __typename
    blockId
    title
    titleAlignment
    type
    disableLocale
    embedSource
    alignment
    pinterestSize
    iFrameHeight
    iFrameWidth
    htmlContent
  }
`;

export const fFunnelStep = gql`
  fragment cbBlockFunnelStep on BlockFunnelStep {
    blockId
    title
    fields: fieldsCollection(limit: 15) {
      items {
        sys {
          id
        }
        name
        type
        label
        placeholder
        options
        optionValues
        errorMessage
        required
        halfWidth
        salesforceFieldApiName
        isHidden
        defaultValue
        sublabel
        description
        ratingType
        maxRatingValue
        lowestRatingText
        highestRatingText
        showWhen
        condition
        conditionValue
      }
    }
    includeTerms
    includeContinueButton
    termsHtml
  }
`;

export const fFunnelStepExtended = gql`
  fragment cbBlockFunnelStepExtended on BlockFunnelStep {
    blockId
    title
    fields: fieldsCollection(limit: 15) {
      items {
        sys {
          id
        }
        name
        type
        label
        placeholder
        options
        optionValues
        errorMessage
        required
        halfWidth
        salesforceFieldApiName
        isHidden
        defaultValue
        sublabel
        description
        ratingType
        maxRatingValue
        lowestRatingText
        highestRatingText
        showWhen
        condition
        conditionValue
        productsCollection(limit: 8) {
          items {
            sys {
              id
            }
            name
            description
            price
            duration
            eventSetupId
          }
        }
      }
    }
    includeTerms
    includeContinueButton
    termsHtml
  }
`;

export const fBlockFunnelStepCalculationDisplay = gql`
  fragment cbBlockFunnelStepCalculationDisplay on BlockFunnelStepCalculationDisplay {
    blockId
    title
    content
    continueButtonText
  }
`;

export const fBlockFunnelStepImageUpload = gql`
  fragment cbBlockFunnelStepImageUpload on BlockFunnelStepImageUpload {
    blockId
    title
    subtitle
    uploads: uploadsCollection(limit: 10) {
      items {
        title
        description
        placeholder {
          ...fImage
        }
      }
    }
  }

  ${fImage}
`;

export const funnelTheme = gql`
  fragment funnelTheme on FunnelTheme {
    name

    generalHeadlineFontFamily
    generalHeadlineFontFamilyUrl
    generalTextFontFamily
    generalTextFontFamilyUrl
    generalHeadlineFontSize
    generalTextFontSize
    generalSpacing
    generalBackgroundColor
    generalTextColor
    generalLinkColor

    submitButtonFontStyle
    submitButtonFontSize
    submitButtonBorderColor
    submitButtonBorderRadius
    submitButtonBorderWidth
    submitButtonDefaultBackgroundColor
    submitButtonDefaultTextColor
    submitButtonHoverBackgroundColor
    submitButtonHoverTextColor
    submitButtonActiveBackgroundColor
    submitButtonActiveTextColor
    submitButtonLoadingBackgroundColor
    submitButtonLoadingTextColor

    inputBorderRadius
    inputBorderWidth
    inputDefaultBorderColor
    inputDefaultBackgroundColor
    inputDefaultTextColor
    inputActiveBorderColor
    inputActiveBackgroundColor
    inputActiveTextColor

    optionBorderColor
    optionBorderRadius
    optionBorderWidth
    optionTextAlign
    optionDefaultBackgroundColor
    optionDefaultTextColor
    optionHoverBackgroundColor
    optionHoverTextColor
    optionActiveBackgroundColor
    optionActiveTextColor

    progressBarBackgroundColor
    progressBarActiveColor
  }
`;

export const cbBlockFunnel = gql`
  fragment cbBlockFunnel on BlockFunnel {
    __typename
    sys {
      id
    }
    blockId
    disableLocale
    treatment
    title
    treatmentName
    steps: stepsCollection(limit: 15) {
      items {
        __typename
        ...cbBlockFunnelStep
        ...cbBlockFunnelStepCalculationDisplay
        ...cbBlockFunnelStepImageUpload
      }
    }
    continueButtonText
    submitButtonText
    includePreviousButton
    previousButtonText
    fullWidth
    salesforceTreatment
    fullscreen
    redirectUrl
    addJunoId
    trustText
    autoSubmit
    logo {
      ...fImage
    }
    customFields
    defaultCountryCode
    forceFullScreen
    theme {
      ...funnelTheme
    }
    jsonModel
  }

  ${fImage}
  ${fFunnelStep}
  ${fBlockFunnelStepCalculationDisplay}
  ${fBlockFunnelStepImageUpload}
  ${funnelTheme}
`;

export const cbBlockFunnelExtended = gql`
  fragment cbBlockFunnelExtended on BlockFunnel {
    __typename
    sys {
      id
    }
    blockId
    disableLocale
    treatment
    title
    treatmentName
    steps: stepsCollection(limit: 15) {
      items {
        __typename
        ...cbBlockFunnelStepExtended
        ...cbBlockFunnelStepCalculationDisplay
        ...cbBlockFunnelStepImageUpload
      }
    }
    continueButtonText
    submitButtonText
    includePreviousButton
    previousButtonText
    fullWidth
    salesforceTreatment
    fullscreen
    redirectUrl
    addJunoId
    trustText
    autoSubmit
    logo {
      ...fImage
    }
    customFields
    defaultCountryCode
    forceFullScreen
    theme {
      ...funnelTheme
    }
    jsonModel
  }

  ${fImage}
  ${fFunnelStepExtended}
  ${fBlockFunnelStepCalculationDisplay}
  ${fBlockFunnelStepImageUpload}
  ${funnelTheme}
`;

export const cbBlockCta = gql`
  fragment cbBlockCta on BlockCta {
    __typename
    sys {
      id
    }
    blockId
    template
    disableLocale
    textContent {
      json
    }
    title
    ctaText
    target
    ctaLink
    triggerLeadForm
    funnel {
      ...cbBlockFunnel
    }
    textAlignment
  }

  ${cbBlockFunnel}
`;

export const fDoctorProfile = gql`
  fragment fDoctorProfile on DoctorProfile {
    firstName
    lastName
    title
    postTitle
    qunoscore
    qunoscorePoints
    slug
    disableLocale
    doctorImage {
      ...fImage
    }
    yearsOfExperienceAsDoctor
    treatmentsPerformedLastYear
    funnel {
      sys {
        id
      }
    }
  }

  ${fImage}
`;

export const cbBlockGenericCta = gql`
  fragment cbBlockGenericCta on BlockGenericCta {
    __typename
    sys {
      id
    }
    id
    disableLocale
    theme
    patientManagerAvatar {
      ...fImage
    }
    patientManagerName
    patientManagerTitle
    patientManagerMessage
    phoneNumberTitle
    phoneNumberText
    phoneNumber
    reviewStars
    reviewText
    reviewAuthor
    reviewAuthorCountry
    ctaText
  }

  ${fImage}
`;

export const cbBlockHeroCta = gql`
  fragment cbBlockHeroCta on BlockHeroCta {
    __typename
    blockId
    disableLocale
    title
    descriptionContent: description {
      json
    }
    buttonText
    buttonAnchor
    isExternalUrl
    backgroundColor
    backgroundImagemobile {
      ...fImage
    }
    backgroundImageDeskop {
      ...fImage
    }
    theme
    fullWidth
  }

  ${fImage}
`;

export const cbBlockHeroFunnel = gql`
  fragment cbBlockHeroFunnel on BlockHeroFunnel {
    __typename
    sys {
      id
    }
    blockId
    disableLocale
    title
    titleHeadingLevel
    disableAfterDescription
    descriptionAfterTitle {
      json
    }
    theme
    backgroundImage {
      ...fImage
    }
    backgroundImageMobile {
      ...fImage
    }
    disableBeforeDescription
    descriptionBeforeFunnel {
      json
    }
    funnelPosition
    funnel {
      ...cbBlockFunnel
    }
    secondaryFunnel {
      ...cbBlockFunnel
    }
    fullWidth
    trustImagesTitle
    trustImages: trustImagesCollection(limit: 5) {
      items {
        ...fImage
      }
    }
    introContentTitle
    introContentText {
      json
    }
    introPrimaryFunnelButton
    secondaryFunnelButton
    useIntroScreen
  }

  ${fImage}
  ${cbBlockFunnel}
`;

export const cbBlockIconRow = gql`
  fragment cbBlockIconRow on BlockIconRow {
    __typename
    sys {
      id
    }
    blockId
    disableLocale
    title
    icons: iconsCollection(limit: 10) {
      items {
        __typename
        sys {
          id
        }
        title
        description
        icon {
          ...fImage
        }
      }
    }
  }

  ${fImage}
`;

export const cbBlockImage = gql`
  fragment cbBlockImage on BlockImage {
    __typename
    sys {
      id
    }
    blockId
    disableLocale
    title
    descriptionContent: description {
      json
    }
    image {
      ...fImage
    }
    imageAlignment
  }

  ${fImage}
`;

export const cbBlockImageGrid = gql`
  fragment cbBlockImageGrid on BlockImageGrid {
    __typename
    sys {
      id
    }
    blockId
    disableLocale
    title
    descriptionContent {
      json
    }
    imageItems: itemsCollection(limit: 15) {
      items {
        __typename
        sys {
          id
        }
        blockId
        title
        description
        image {
          ...fImage
        }
        imageLink
      }
    }
  }

  ${fImage}
`;

export const cbBlockImageRow = gql`
  fragment cbBlockImageRow on BlockImageRow {
    __typename
    blockId
    disableLocale
    descriptionContent: description {
      json
    }
    imageItems: itemsCollection(limit: 15) {
      items {
        blockId
        disableLocale
        title
        descriptionContent: description {
          json
        }
        image {
          ...fImage
        }
        imageAlignment
      }
    }
  }

  ${fImage}
`;

export const cbBlockLink = gql`
  fragment cbBlockLink on BlockLink {
    __typename
    blockId
    title
    url
  }
`;

export const cbBlockQuickDetails = gql`
  fragment cbBlockQuickDetails on BlockQuickDetails {
    __typename
    sys {
      id
    }
    blockId
    disableLocale
    title
    quickDetails: quickDetailsCollection(limit: 10) {
      items {
        __typename
        sys {
          id
        }
        title
        text {
          json
        }
      }
    }
  }
`;

export const cbBlockTable = gql`
  fragment cbBlockTable on BlockTable {
    __typename
    blockId
    disableLocale
    title
    columns
    columnNames
    tableItems
  }
`;

export const cbBlockTestimonial = gql`
  fragment cbBlockTestimonial on BlockTestimonial {
    __typename
    image {
      ...fImage
    }
    name
    location
    treatment
    testimonial
  }

  ${fImage}
`;

export const cbBlockTimeline = gql`
  fragment cbBlockTimeline on BlockTimeline {
    __typename
    sys {
      id
    }
    blockId
    disableLocale
    title
    descriptionContent: description {
      json
    }
    timelineItems: itemsCollection(limit: 10) {
      items {
        __typename
        sys {
          id
        }
        title
        isClosingItem
        descriptionContent {
          json
        }
      }
    }
  }
`;

export const cbBlockTreatmentsCard = gql`
  fragment cbBlockTreatmentsCard on BlockTreatmentsCard {
    __typename
    image {
      ...fImage
    }
    title
    treatments: treatmentsCollection(limit: 15) {
      items {
        slug
        disableLocale
        title
        speciality {
          ...fSpeciality
        }
        treatmentName
      }
    }
    additionalTreatments
    disableAdditionalTreatments
  }

  ${fImage}
  ${fSpeciality}
`;

export const cbBlockTreatments = gql`
  fragment cbBlockTreatments on BlockTreatments {
    __typename
    blockId
    disableLocale
    title
    treatmentItems: itemsCollection(limit: 10) {
      items {
        sys {
          id
        }
        image {
          ...fImage
        }
        title
        treatments: treatmentsCollection(limit: 10) {
          items {
            slug
            title
            disableLocale
            speciality {
              slug
              title
            }
          }
        }
        additionalTreatments
        disableAdditionalTreatments
      }
    }
  }

  ${fImage}
`;

export const cbBlockUkraineRefugeeDoctorsList = gql`
  fragment cbBlockUkraineRefugeeDoctorsList on BlockUkraineRefugeeDoctorsList {
    __typename
    blockId
  }
`;

export const cbBlockUppyImageUpload = gql`
  fragment cbBlockUppyImageUpload on BlockUppyImageUpload {
    __typename
    blockId
    source
    title
    descriptionContent: description {
      json
    }
  }
`;

export const cbBlockYoutube = gql`
  fragment cbBlockYoutube on BlockYoutube {
    __typename
    sys {
      id
    }
    blockId
    disableLocale
    title
    descriptionContent: description {
      json
    }
    youtubeVideoId
    alignment
  }
`;

export const cbColumnBlock3 = gql`
  fragment cbColumnBlock3 on ColumnBlock3 {
    __typename
    sys {
      id
    }
    blockId
    disableLocale
    titleColumn1
    textColumn1 {
      json
    }
    titleColumn2
    textColumn2 {
      json
    }
    titleColumn3
    textColumn3 {
      json
    }
  }
`;

export const cbImageText = gql`
  fragment cbImageText on ImageText {
    __typename
    sys {
      id
    }
    blockId
    disableLocale
    position
    textContent {
      json
    }
    image {
      ...fImage
    }
    imageDescription {
      json
    }
  }

  ${fImage}
`;

export const cbInfoboxBlock = gql`
  fragment cbInfoboxBlock on InfoboxBlock {
    __typename
    sys {
      id
    }
    blockId
    disableLocale
    theme
    title
    textContent {
      json
    }
  }
`;

export const cbRichText = gql`
  fragment cbRichText on ContentTypeRichText {
    __typename
    sys {
      id
    }
    blockId
    disableLocale
    textContent {
      json
    }
  }
`;

export const cbAuthor = gql`
  fragment cbAuthor on Author {
    __typename
    sys {
      id
    }
    name
    slug
    description
    photo {
      ...fImage
    }
  }

  ${fImage}
`;

export const cbFullAuthor = gql`
  fragment cbFullAuthor on Author {
    __typename
    sys {
      id
    }
    name
    slug
    photo {
      ...fImage
    }
    blocks: blocksCollection(limit: 10) {
      items {
        __typename
        sys {
          id
        }
        blockId
        disableLocale
        textContent {
          json
        }
      }
    }
    metaTitle
    metaDescription
    jobTitle
    knowsAbout
    honorificPrefix
    honorificSuffix
    alumniOfName
    sameAs
    description
    highlight1
    highlight2
    affiliationType
    affiliationName
    affiliationUrl
    alumniOfType
    alumniOfName
    alumniOfUrl
    city {
      ...fCity
    }
  }

  ${fImage}
  ${fCity}
`;
